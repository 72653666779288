import React from "react"
import {I18NextWrapper} from "../loaders/i18nextInit";
import "../styles/index.css"
import {Loading} from "../components/Loading";
import { Col, Row } from "antd"
import { sectionLayout } from "../utils/constants"
import { config } from "../config"

const Dps = () => {
  return (
    <>
      <I18NextWrapper loading={<Loading/>}>
        <Row>
          <Col {...sectionLayout} style={{height: '100vh', minHeight: '400px'}}>
            <iframe
              width="100%"
              height="100%"
              src={config.links.drive.dps} />
          </Col>
        </Row>
      </I18NextWrapper>
    </>
  )
}

export default Dps
